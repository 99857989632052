.modal-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #213a7d;
}

.modal-footer {
  display: block !important;
}

label {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #697386;
}

.modal-row {
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 24px;
}
#buttonModal{
  marginLeft: "8.5%";
   marginRight: "8.5%" ;
}