.company-item:hover {
  color: #ffffff;
  background: #709dd3;
  border-radius: 5px;
  padding: 0%;
}
@media (max-width:991px) {
  #titleMenu{
    display: none;
  }
}


@media (min-width:850px){
  #iconMobile{
    margin-left: 12px !important;
    margin-right: 12px !important;
    margin-top:12px !important;
    margin-bottom:12px !important
  }
}

@media (max-width:849px) and (min-width:600px) {
  #iconMobile{
    margin-left: 1% !important;
    margin-right: 12px !important;
    margin-top:12px !important;
    margin-bottom:12px !important
  }
}
@media  (max-width:599px) and (min-width:577px) {
  #iconMobile{
    margin-left: 0px !important;
    margin-right: 12px !important;
    margin-top:12px !important;
    margin-bottom:12px !important
  }
}
@media  (max-width:575px) and (min-width:450px) {
  #iconMobile{
    margin-left: 2% !important;
    margin-right: 12px !important;
    margin-top:12px !important;
    margin-bottom:12px !important
  }
}
@media  (max-width:449px)  {
  #iconMobile{
    margin-left: 1% !important;
    margin-right: 12px !important;
    margin-top:12px !important;
    margin-bottom:12px !important
  }
}
