.navlink :hover {
    text-align: center;
    color: blue !important
  }
  
  .badgeAvatar {
  
    position: relative;
    top: 40px;
    right: 29px;
    width: 32px;
    height: 32px;
    border: 2px solid white;
  }
  
  .camera {
    border-radius: 8px;
    position: relative;
    right: 3px;
  
    color: aliceblue;
  }
  
  .card {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
  
  .contact label {
    color: #697386 !important
  }
  
  .contact input {
    border: 0 !important;
  }
  
  .contact input:disabled {
  
    background-color: white;
  }
  
  .sociéte label {
    color: #697386 !important
  }
  
  .sociéte input {
    border: 0 !important
  }
  
  .sociéte input:disabled {
  
    background-color: white;
  }
  
  
  
  
  .switch {
  
    display: flex !important;
    flex-direction: row-reverse !important;
    justify-content: space-between !important;
    align-items: start;
    border: 1px solid #D9DCE1;
    border-radius: 5px;
    /* padding-right: 15px;
    padding-left: 5% !important; */
    padding: 7px 15px 7px 5% !important;
    margin: 10px !important;
  }
  
  
  
  .switch label {
    color: black !important
  }
  
  .formulaire input::placeholder {
    color: grey
  }
  
  .input-group {
    position: relative;
  }
  
  .input-group input:disabled {
  
    background-color: white;
  }
  
  .input-group .icon-copy {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
  
  .motDePasse label {
    color: #697386 !important
  }
  
  .dropzone {
  
    /* border: 2px dashed #ccc !important; */
    margin: 1% !important;
    /* padding-bottom: 20px !important; */
    /* height: 214px; */
    text-align: center;
    padding-bottom:"5%" !important;

  }


  .outlined-button-with-icon {
    border: 1px solid #c2c7cf;
    padding: 6px 12px;
    color: #3c4257;
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
      0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }