.blue-backdrop {
  background-color: rgba(0, 0, 0, 0) !important;
}
.activate-link {
  font-family: 'apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu"';
  font-weight: 600;
  text-decoration: none;
  color: white;
  transition: color 0.3s;
}

.activate-link:hover {
  color: blue;
  cursor: pointer;
}

button.select-button:hover {
  background: #e3e8ee;
  border: 1px solid #c2c7cf;
  border-radius: 4px;
  color: #3c4257;
}

.option-content {
  display: flex;
  align-items: center;
}

.option-content:hover {
  background-color: #f6f8fa;
}

.icon {
  align-self: flex-start;
  margin-right: 10px;
}

.text {
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: bold;
}

.description {
  margin-top: 5px;
  color: #666;
}

.option:hover {
  background-color: #f6f8fa;
  cursor: pointer;
}

.paylink-checkout-page .nav-link {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #697386;
}

.paylink-checkout-page .nav-link.active {
  color: #5469d4;
  width: fit-content;
  padding: 10px;
}

.paylink-checkout-page .nav-link:not(.active) {
  /* color: green; */
}
.paylink-checkout-page .nav-link:hover {
  /* background-color: #ccc; */
  cursor: pointer;
  color: #625afa;
}

.paylink-checkout-page .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: none;
  border-bottom: 3px solid #625afa;
}

/* .icon {
  background-color: #3498db;
  color: #fff;
  border-radius: 50%;
  padding: 5px;
  margin-left: auto;
}
 */

button.return-url-btn {
  position: absolute;
  left: 15px;
  background-color: #b9d9eb;
  padding: 8px 12px;
  color: #212529;
  border: none;
}

button.return-url-btn:hover {
  background-color: #a0c4d6;
  color: #212529;
}

.no-resizable-textarea {
  resize: none;
}

.product-list-selected:hover {
  background-color: rgb(233, 239, 248);
}
