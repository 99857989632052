.outlined-button {
    border: 1px solid #C2C7CF;
    padding: 6px 12px;
    color: #3C4257;
    background: #FFFFFF;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.outlined-button:hover {
    background: #E3E8EE;
    border: 1px solid #C2C7CF;
    border-radius: 4px;
    color: #3C4257;
}

.outlined-button:active {
    background: #E3E8EE;
    border: 1px solid #C2C7CF;
    border-radius: 4px;
    color: #3C4257;
}

.outlined-button:disabled {

    background: #FFFFFF;
    border: 1px solid #C2C7CF;
    border-radius: 4px;
    color: #A3ACB9;

}

.outlined-button-with-icon {
    border: 1px solid #C2C7CF;
    padding: 6px 12px;
    color: #3C4257;
    background: #FFFFFF;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.outlined-button-with-icon:hover {
    background: #E3E8EE;
    border: 1px solid #C2C7CF;
    border-radius: 4px;
    color: #3C4257;
}

.outlined-button-with-icon:active {
    background: #E3E8EE;
    border: 1px solid #C2C7CF;
    border-radius: 4px;
    color: #3C4257;
}

.outlined-button-with-icon:disabled {

    background: #FFFFFF;
    border: 1px solid #C2C7CF;
    border-radius: 4px;
    color: #A3ACB9;

}

.button-with-icon{
    background: #213A7D;
    border-radius: 4px;
}
.button-with-icon:hover{
    background: #125FB9;
    border-radius: 4px;
}
.button-with-icon:active{
    background: #5469D4;
    border-radius: 4px;
}
.button-with-icon:disabled{
    background: #63A4FF;
    border-radius: 4px;
}

.button-without-icon{
    background: #034DA3;
    border-radius: 4px;
}
.button-without-icon:hover{
    background: #125FB9;
    border-radius: 4px;
}
.button-without-icon:active{
    background: #5469D4;
    border-radius: 4px;
}
.button-without-icon:disabled{
    background: #63A4FF;
    border-radius: 4px;
}

#statusPaid {
    background-color: #CBF4C9;
    color: #0E6245;
    border-radius: 5px;
    font-size: 12px;
    padding-top: 0.5px;
    padding-bottom: 3px;
    padding-right: 7px;
    padding-left: 10px;
    font-weight: 500;
}

#statusWaitin{
    background-color: yellow;
    color: #212529;
    border-radius: 5px;
    font-size: 12px;
    padding-top: 0.5px;
    padding-bottom: 3px;
    padding-right: 7px;
    padding-left: 10px;
    font-weight: 500;
}
#containerTest{
    overflow-y: "auto" !important;
    height: "90vh" !important;
    overflow-x: hidden !important;
}


/* .form-select {
    --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e);
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: gray !important;
    appearance: none;
    background-color: var(--bs-body-bg);
    background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */