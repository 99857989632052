.card-body .progress {
  background-color: #e6e9ef;
  height: 10px;
  width: 100%;
  border-radius: 0;
}

.card-body .progress .progress-bar {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.product-item .product-item-label {
  color: #515151;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.product-item .product-item-description {
  color: #bcbcbc;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

.product-item .product-item-price {
  color: #515151;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}

/* .product-item:hover {
  background: #f4f4f4;
  cursor: pointer;
} */

/* .product-item:hover .product-item-label,
.product-item:hover .product-item-price {
  color: #898989;
} */

/* .product-item:hover .product-item-description {
  color: #cecccc;
} */

.product-item-disabled {
  background: #f4f4f4;
  cursor: not-allowed;
}

.product-item-disabled .product-item-label,
.product-item-disabled .product-item-price {
  color: #898989;
}

.product-item-disabled .product-item-description {
  color: #cecccc;
}

.payment-step-text {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

.payment-step-text--white {
  color: white;
}

.payment-step-text--gray {
  color: #bcbcbc;
}

.clone-product-btn {
  background-color: #63a4ff !important;
  border: none;
  width: 50px;
  height: 25px;
  transition: background-color 0.3s ease;
}

.clone-product-btn:hover {
  background-color: #4b8edb !important;
}

.remove-product-btn {
  background-color: #d83a52 !important;
  border: none;
  width: 50px;
  height: 25px;
  transition: background-color 0.3s ease;
}

.remove-product-btn:hover {
  background-color: #b23045 !important;
}

.delivery-option {
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.delivery-option:hover {
  border-color: #6298e2;
}

.delivery-option--selected {
  border: 1px solid #034da3;
}

.table-rows::-webkit-scrollbar {
  width: 8px;
}

.table-rows::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.table-rows::-webkit-scrollbar-thumb {
  background-color: #709dd3;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.table-rows::-webkit-scrollbar-thumb:hover {
  background-color: #709dd3;
  cursor: pointer;
}

.product-list::-webkit-scrollbar {
  width: 10px;
}

.product-list::-webkit-scrollbar-track {
  background: #cecece;
  border-radius: 10px;
}

.product-list::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 10px;
  border: 2px solid #cecece;
}

.product-list::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
  cursor: pointer;
}

.delivery-options::-webkit-scrollbar {
  width: 10px;
}

.delivery-options::-webkit-scrollbar-track {
  background: #cecece;
  border-radius: 10px;
}

.delivery-options::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 10px;
  border: 2px solid #cecece;
}

.delivery-options::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
  cursor: pointer;
}
