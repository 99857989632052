
.dropzone-container{
    padding: 20px;
    border: 2px dashed #3D66D2;
    border-radius: 10px;
    height: 150px;
}
.dropzone-container-error{
    padding: 20px;
    border: 2px dashed red;
    border-radius: 10px;
    height: 190px;
}

.dropzone-container:hover {
    background-color: #E9EFF8;
    cursor: pointer;
}
.dropzone-container-error:hover {
    background-color: #E9EFF8;
    cursor: pointer;
}
.dropzone-loader{
    background: #CEE6FF;
    border-radius: 5px;
}
.upload-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height, or 125% */

    align-items: center;
    text-align: center;

    /* Primary */

    color: #213A7D;
}

.upload-format{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height, or 125% */

    align-items: center;
    text-align: center;

    /* Secondary */

    color: #3D66D2;
}

.progress-bar{
    background-color: #034DA3;
    color: #034DA3;
}