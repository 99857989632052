input {
  background: #ffffff;
  border: 1px solid #d9dce1;
  border-radius: 5px;
  color: "#A3ACB9";
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  height: 44px;
}

input[type="text"]:focus {
  outline: none;
  border: 1px solid #a3acb9;
  border-radius: 5px;
}

.input-group input:focus {
  outline: none;
  border: 1px solid #a3acb9;
  border-radius: 5px;
  box-shadow: none;
}

.input-group input:focus + .input-group-text {
  border: 1px solid #a3acb9;
  border-radius: 5px;
}

.has-error {
  border: 1px solid #b42c2c;
}

.has-success {
  border: 1px solid #0e6245;
}

.input-valid-icon {
  margin-left: -30px;
  cursor: pointer;
  color: #0e6245;
}

/* .input-group input ::placeholder {
  color: #a3acb9;
  font-size: 14px;
} */

.fa-eye {
  margin-left: -30px;
  cursor: pointer;
  color: lightgray;
}

.fa-eye-error {
  margin-left: -30px;
  cursor: pointer;
  color: #b42c2c;
}
