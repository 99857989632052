.preview-checkout-link .nav-link {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #697386;
}

.preview-checkout-link .nav-link.active {
  color: #5469d4;
  width: fit-content;
  padding: 10px;
}

.preview-checkout-link .nav-link:not(.active) {
  /* color: green; */
}
.preview-checkout-link .nav-link:hover {
  /* background-color: #ccc; */
  cursor: pointer;
  color: #5469d4;
}

.preview-checkout-link .nav-tabs .nav-link.active,
.preview-checkout-link .nav-tabs .nav-item.show .nav-link {
  border: none;
  border-bottom: 5px solid #213a7d;
}

.circular-button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.product-item .product-item-label {
  color: #515151;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.product-item .product-item-description {
  color: #bcbcbc;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

.product-item .product-item-price {
  color: #515151;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}

.payment-step-text {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

.payment-step-text--white {
  color: white;
}

.payment-step-text--gray {
  color: #bcbcbc;
}

.clone-product-btn {
  background-color: #63a4ff !important;
  border: none;
  width: 50px;
  height: 25px;
  transition: background-color 0.3s ease;
}

.clone-product-btn:hover {
  background-color: #4b8edb !important;
}

.remove-product-btn {
  background-color: #d83a52 !important;
  border: none;
  width: 50px;
  height: 25px;
  transition: background-color 0.3s ease;
}

.remove-product-btn:hover {
  background-color: #b23045 !important;
}

.delivery-option {
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.delivery-option:hover {
  border-color: #6298e2;
}

.delivery-option--selected {
  border: 1px solid #034da3;
}

.product-list::-webkit-scrollbar {
  width: 10px;
}

.product-list::-webkit-scrollbar-track {
  background: #cecece;
  border-radius: 10px;
}

.product-list::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 10px;
  border: 2px solid #cecece;
}

.product-list::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
  cursor: pointer;
}
