.outlined-button {
    border: 1px solid #C2C7CF;
    padding: 6px 12px;
    color: #3C4257;
    background: #FFFFFF;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.outlined-button:hover {
    background: #E3E8EE;
    border: 1px solid #C2C7CF;
    border-radius: 4px;
    color: #3C4257;
}

.outlined-button:active {
    background: #E3E8EE;
    border: 1px solid #C2C7CF;
    border-radius: 4px;
    color: #3C4257;
}

.outlined-button:disabled {

    background: #FFFFFF;
    border: 1px solid #C2C7CF;
    border-radius: 4px;
    color: #A3ACB9;

}