table {
  border-collapse: collapse;
}

.table-rows {
  /* border-collapse:separate; */
  border-spacing: 0 10px;
}

.table-rows tr {
  padding: 11px 10px;
  background: #ffffff;
  /* border: 1px solid #CEE6FF; */
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

.table-rows tr td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table-rows tr td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.table-header {
  margin-bottom: 10px;
}

.table-header th {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  color: #1a1f36;
  background: #eaf4ff;
}

.table-header tr th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table-header tr th:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

th,
td {
  width: auto;
  text-align: center;
  height: 40px;
  padding: 5px;
}

td {
  border: 1px solid #cee6ff;
  color: #4f566b;
}

.table-column {
  border-right: hidden;
}

.table-rows .table-row:not(:last-child) {
  border-bottom: none;
}

.table-rows .button-without-icon {
  background: #213a7d;
  border-radius: 4px;
}

.table-rows .button-without-icon:hover {
  background: #125fb9;
  border-radius: 4px;
}

.table-rows .button-without-icon:active {
  background: #5469d4;
  border-radius: 4px;
}

.table-rows .button-without-icon:disabled {
  background: #63a4ff;
  border-radius: 4px;
}

.table-body {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: #cee6ff !important;
}

.table-body tr td:first-child {
  border-left: 1px solid #cee6ff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.table-body tr td:last-child {
  border-right: 1px solid #cee6ff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
