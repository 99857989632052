.dashboard-content .page-title {
  font-family: "Inter";
  font-style: "normal";
  font-weight: "700";
  font-size: "28px";
  line-height: "34px";
  color: "#1A1F36";
}

.dashboard-content .widget {
  background: #e9eff8;

  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12),
    0px 2px 5px rgba(60, 66, 87, 0.08);
  border-radius: 8px;
}

.dashboard-content .widget-indicator {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.multi-step-progressbar {
  margin-left: 140px;
  z-index: 0;
}

.main-wrapper .nav-link {
  font-weight: 400;
  font-size: 16px;
  color: #171717;
  padding-left: 0%;
}
.main-wrapper .nav-link:hover {
  color: #5469d4;
}

#menu-sub-item {
  font-weight: 500;
  font-size: 14px;
  color: #171717;
}
#menu-sub-item:hover {
  color: #5469d4;
}
#menu-sub-item.active {
  font-weight: 500;
  font-size: 14px;
  text-decoration: underline;
  color: #5469d4;
  background: none;
}

#progressbar {
  position: absolute;
  left: 35px;
  overflow: hidden;
  color: #e53935;
}

#progressbar li {
  list-style-type: none;
  font-size: 8px;
  font-weight: 400;
  margin-bottom: 36px;
}

#progressbar li:nth-child(3) {
  margin-bottom: 88px;
}

#progressbar .step0:before {
  content: "";
  color: #fff;
}

#progressbar li:before {
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: block;
  font-size: 20px;
  background: #fff;
  border: 2px solid #e53935;
  border-radius: 50%;
  margin: auto;
}

#progressbar li:last-child:before {
  width: 40px;
  height: 40px;
}

#progressbar li:after {
  content: "";
  width: 3px;
  height: 66px;
  background: #bdbdbd;
  position: absolute;
  top: 15px;
  z-index: -1;
}

#progressbar li:last-child:after {
  top: 147px;
  height: 132px;
}

#progressbar li:nth-child(3):after {
  top: 81px;
}

#progressbar li:nth-child(2):after {
  top: 0px;
}

#progressbar li:first-child:after {
  position: absolute;
  top: -81px;
}

#progressbar li.active:after {
  background: #e53935;
}

#progressbar li.active:before {
  background: #e53935;
  font-family: FontAwesome;
  content: "\f00c";
}
#container{
  max-width: 100%;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

  .main-wrapper .nav-link.active {
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    width: 16rem;
    height: 39px;
    background: #63a4ff;
    border-radius: 5px;
  }


@media (min-width:1200px) {
  #content-page{
    overflow: hidden;
   margin-left: 2rem;
   margin-right: 2rem;
   margin-bottom: 4rem;
  }
}
@media (min-width:1000px) and (max-width:1199px ) {
  #content-page{
    overflow: hidden;
   margin-left: 2rem;
   margin-right: 8rem;
   margin-bottom: 4rem;
  }
  #lgScreen{
    margin-top: 1.5rem;
  }
}
@media (min-width:992px) and (max-width:999px ) {
  #content-page-lg{
    overflow-x: hidden;
    margin-left: 2rem;
    margin-right: 17rem;
    margin-bottom: 4rem;
    overflow-y: scroll;
    height: 100vh;
  }
}
@media (min-width:350px) and (max-width:991px ) {
  #content-page-lg{
    overflow-x: hidden;
    margin-left: 2rem;
    margin-right: 7rem;
    margin-bottom: 4rem;
    overflow-y: scroll;
    height: 100vh;

  }
}
@media (min-width:1201px)  {
  #textMinSize{
   font-size: 20px;

  }
}

@media (max-width:1200px)  {
  #textMinSize{
   font-size: 18px;

  }
}
@media (max-width:1000px)  {
  #textMinSize{
   font-size: 16px;

  }
}